import momentTimezone from 'moment-timezone';

export function getAgeAndBirthMessage(name, masculine, dateOfBirth, weight, length, dateOfDeath) {
  let message = getAgeMessage(name, masculine, dateOfBirth, dateOfDeath);

  if (weight && length) {
    message += ` ${masculine ? "He" : "She"} was ${weight} and ${length} long.`;
  }

  return message;
}

function getDuration(start, end){
  const dateDiff = start.date() > end.date() ? start.daysInMonth() - start.date() + end.date() : end.date() - start.date();
  return `${end.diff(start, 'y')} years, ${end.diff(start, 'M') % 12} months and ${dateDiff} days old`;
}

export function getAgeMessage(name, masculine, dateOfBirth, dateOfDeath) {
  const shortFormat = 'MMMM D, YYYY';
  const longFormat = 'MMMM D, YYYY [at] h:mma z';
  const now = momentTimezone().tz(momentTimezone.tz.guess());
  const dateDiff = dateOfBirth.date() > now.date() ? dateOfBirth.daysInMonth() - dateOfBirth.date() + now.date() : now.date() - dateOfBirth.date();
  
  let ageAtDeath = "";
  let deceased = false;
  if (dateOfDeath !== undefined){
    deceased = true;
    ageAtDeath = ` and died on ${dateOfDeath.format(shortFormat)}, ${masculine ? 'he' : 'she'} was ${getDuration(dateOfBirth, dateOfDeath)}`;
  }

  let message = `${name}, was born on ${dateOfBirth.format(dateOfBirth.format('h:mma') === '12:00am' ? shortFormat : longFormat)}${ageAtDeath}${deceased ? '.' : ', which makes '}`;// ${deceased ? `If ${masculine ? 'he' : 'she'} was still alive, ` : 'Which makes '} `;

  if(!deceased){
    if (dateDiff === 0 && now.month() - dateOfBirth.month() === 0) {
      message += `today is ${masculine ? 'his' : 'her'} birthday, ${masculine ? 'he' : 'she'} is ${now.diff(dateOfBirth, 'y')} years old! 🎉🎂`;
    } else {
      message += `${(masculine ? 'him' : 'her')} ${getDuration(dateOfBirth, now)}.`;
    }
  }
  return message;
}
